import { bb } from "$json";
/* sb:open:
 *
 * Open de snelbalie
 *
 * Author: niels giesen
 * Copyright 2012, 2013, 2015, 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    function open() {
      bb.ajax.post({
        url: "open",
        data: {
          modelname: "vl",
          fmt: "json"
        }
      });
    }

    function hasState() {
      try {
        return (
          win.history &&
          win.history.pushState &&
          (win.history.state ||
            (win.sessionStorage &&
              "JSON" in window &&
              JSON.parse(win.sessionStorage.getItem("state"))))
        );
      } catch (err) {
        return false;
      }
    }

    if (win.location.search.length === 0 && !hasState()) {
      open();
    } else {
      $(doc).one("bb:postHandleData", function (event, data) {
        if (
          (data && data.error && data.error.summary) ||
          (data && data.groups && !data.groups.length)
        ) {
          open();
        }
      });
    }
  });
})(jQuery, window, document);
