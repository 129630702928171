import { bb } from "$json";
/* postcode-soap:
 *
 * Prefill street and city fields based on postcode (dutch). Assumes a
 * Webservice running on the same host at the path ../postcode/postcode,
 * relative to document, accepting a soap request in the form returned by the
 * function soapBody().
 *
 * Fields are matched (fuzzily) by their accompanying labels:
 *
 * /[Pp]ostcode/ -> get postcode
 * /[Ss]traat/   -> set street
 * /[Pp]laats/   -> set cityname
 *
 * Copyright (C) 2013 by Berkeley Bridge
 *
 */

(function ($) {
  var url = bb.propFinder(bb.conf, "arbitrary.postcode-soap")(
    "url",
    "../postcode/postcode"
  );

  function soapBody(cijfers, letters) {
    return `<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"
 xmlns:bb="www.berkeleybridge.nl/soap">
   <soap:Header/>
   <soap:Body>
      <bb:applymodel>
         <bb:modelname>postcode</bb:modelname>
         <bb:username>postcode</bb:username>
         <bb:password>nietgeheimhoor</bb:password>
         <bb:postcode>
            <bb:cijfers>${cijfers}</bb:cijfers>
            <bb:letters>${letters}</bb:letters>
         </bb:postcode>
      </bb:applymodel>
   </soap:Body>
</soap:Envelope>`;
  }

  $(function () {
    function fillData(cijfers, letters, elt) {
      $.ajax({
        type: "post",
        url: url,
        contentType: "text/xml; charset=utf-8",
        data: soapBody(cijfers, letters),
        dataType: "xml",
        processData: false,
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "SOAPAction",
            "http://www.bridge-to-knowledge.nl/" + "postcode/postcode"
          );
        },

        success: function (msg) {
          var txt = msg;
          if (!txt) this.error.apply(this, arguments);
          else {
            var streetNode, street, citynameNode, cityname;
            // Non-IE
            if (typeof txt.getElementsByTagNameNS === "function") {
              street = txt.getElementsByTagNameNS(
                "www.berkeleybridge.nl/soap",
                "street"
              )[0].textContent;
              cityname = txt.getElementsByTagNameNS(
                "www.berkeleybridge.nl/soap",
                "cityname"
              )[0].textContent;
            } else {
              // IE
              msg.setProperty(
                "SelectionNamespaces",
                'xmlns:s="http://schemas.xmlsoap.org/soap/envelope/" ' +
                  'xmlns:bb="www.berkeleybridge.nl/soap"'
              );
              streetNode = msg.selectSingleNode(
                "s:Envelope/s:Body/bb:postcode-data-out/bb:street"
              );
              if (streetNode) street = streetNode.text;
              citynameNode = msg.selectSingleNode(
                "s:Envelope/s:Body/bb:postcode-data-out/bb:cityname"
              );
              if (citynameNode) cityname = citynameNode.text;
            }
            $.each(
              $(elt).parents(".group, tr").first().find('input[type="text"]'),
              function () {
                if (/^([Ss]traat|[Aa]dres)/.test($(this).data("label"))) {
                  $(this).val(street);
                } else if (
                  /([Pp]laatsnaam|[Ww]oonplaats)/.test($(this).data("label"))
                ) {
                  $(this).val(cityname);
                }
              }
            );
          }
        },
        error: function () {
          if (typeof console != "undefined" && console && console.log)
            console.log("error getting postcode", arguments);
        }
      });
    }

    function autofillothers() {
      var pc = $.trim($(this).val());
      var matches = pc.match(/^([0-9]{4})\s*([a-zA-Z]{2})$/);
      if (matches && matches.length == 3) {
        fillData(matches[1], matches[2], this);
      }
    }

    $(document).on("bb:postHandleData", function () {
      $(".group.selected" + ' input[data-datatype*="StUF:Postcode"]').each(
        function () {
          $(this).on("keydown", function () {
            var val = $(this).val();
            var me = this;
            var fun = function () {
              if (val !== $(me).val()) autofillothers.call(me);
            };
            window.setTimeout(fun, 200);
          });
        }
      );
    });
  });
})(jQuery);
