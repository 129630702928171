import { bb } from "$json";
import { _ } from "$json/lib/gettext";
import {
  compose,
  join,
  maybeNumber,
  path,
  pick,
  tap
} from "$json/lib/functional";
import { getControl } from "$json/lib/control.js";
import { getVar } from "$json/lib/vars";
/* option-filter:
 *
 * Add search box to filter options
 *
 * Author: Niels Giesen
 * Copyright 2013, 2022 Berkeley Bridge
 *
 */
export const threshold = bb.propFinder(bb.conf)(
  "arbitrary.option-filter.threshold",
  7
);

export const types = ["UL", "TABLE"];

export const optionIsChecked = option => {
  const input = option.querySelector("input");
  if (!input) return false;
  return input.matches(":checked");
};

export const toggleOption = (matches, option) => {
  option.classList.toggle("p-option-filter__hidden", !matches);
  option.style.display = matches ? "" : "none";
};

const selectors = {
  UL: `.group.selected .bb-option-list`,
  TABLE: `.group.selected [data-type="grid"]`
};

const selector = compose(join(", "), Object.values, pick(types))(selectors);

(function ($) {
  // Options BEGIN
  var translations = {
    "search term": {
      nl: "zoekterm",
      fr: "terme de recherche",
      de: "Suchbegriff"
    }
  };
  // Options END
  const getSavedId = control =>
    $(control).data("control").id + "--" + getVar("sessionid");

  const savedQueries = {};
  const handled = new WeakMap();

  $(function () {
    _.addTranslations(translations);

    const template =
      '<input type="search" data-type="search" placeholder="' +
      _("search term") +
      '" autocomplete="off" class="p-option-filter"/>';

    function isSucceptibleList(list) {
      return (
        list.matches("UL") ||
        compose(
          Boolean,
          maybeNumber,
          path(["metadata", "p-option-filter"]),
          getControl
        )(list)
      );
    }

    function getSucceptibleLists() {
      return [...document.querySelectorAll(selector)].filter(isSucceptibleList);
    }

    function trigger(filter, checklist) {
      return function () {
        $(checklist).trigger("bb-p:option-filter", {
          checklist: checklist,
          value: $(filter).val().toLowerCase()
        });
      };
    }

    function listExceedsThreshold(list) {
      return list.matches(`:has(tr:nth-child(${threshold})),
:has(.bb-option:nth-child(${threshold}))`);
    }

    function manageFilter(list) {
      if (!listExceedsThreshold(list)) {
        if (handled.has(list)) {
          const filter = handled.get(list).get(0);
          filter.parentNode.removeChild(filter);
          handled.delete(list);
        }
        return;
      }
      if (handled.has(list)) return;
      const $filter = $(template);
      const label = document.getElementById($(list).attr("aria-labelledby"));
      const savedId = getSavedId(list);
      if (label) $filter.insertAfter(label);
      else $filter.insertBefore(list);
      const onFilterUpdate = trigger($filter, list);
      $filter.on(
        "keyup input", // 'input' needed for clear-×
        onFilterUpdate
      );
      $filter.on("keyup input", function () {
        savedQueries[savedId] = $filter.val();
      });
      $(list).on("bb-p:option-filter", filterOptions);
      // Now check if the end user already input a query before changing nodes.
      if (savedQueries[savedId] !== undefined) {
        $filter.val(savedQueries[savedId]);
        onFilterUpdate();
      }
      handled.set(list, $filter);
    }

    function filterOptions(_e, data) {
      const type =
        this.getAttribute("data-hiddensearch") === null ? "normal" : "hidden";

      if (this.matches("UL")) {
        $(this)
          .find(".bb-option")
          .each(function () {
            var $option = $(this),
              text = $option.text().toLowerCase(),
              test =
                type === "normal"
                  ? data.value.length <= 2 ||
                    optionIsChecked(this) ||
                    text.indexOf(data.value) > -1
                  : data.value.length <= 2
                    ? optionIsChecked(this)
                    : text.indexOf(data.value) > -1 || false;
            toggleOption(test, this);
          });
      } else if (this.matches("TABLE")) {
        $(this)
          .find("tbody tr")
          .each(function () {
            const text = this.textContent.toLowerCase(), // Note will also match inline error messages...
              test =
                type === "normal"
                  ? data.value.length <= 2 ||
                    optionIsChecked(this) ||
                    text.indexOf(data.value) > -1
                  : data.value.length <= 2
                    ? optionIsChecked(this)
                    : optionIsChecked(this) ||
                      text.indexOf(data.value) > -1 ||
                      false;
            toggleOption(test, this);
          });
      }
      this.dispatchEvent(new Event("bb-p:option-filtered"));
    }

    $(document).on("bb:postHandleData", function (_event, data) {
      if (data && data.groups) {
        const lists = getSucceptibleLists();
        lists.forEach(function (list) {
          manageFilter(list);
        });
      }
    });

    $(document).on("bb:updated", function (_event, _$widget, control, updates) {
      if (
        updates.includes("value") &&
        ["grid", "checkmultilist", "radiogroup"].includes(control.controltype)
      ) {
        const list = _$widget.get(0);
        if (isSucceptibleList(list)) manageFilter(list);
      }
    });
  });
})(jQuery);
