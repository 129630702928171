import { bb } from "$json";
/* faux-ajax:
 *
 * Submit form through an iframe, trigger our own events on response
 *
 * Submit a form by calling bb.Plugins['faux-ajax'].send(form);
 *
 * This plugin also automatically intercepts any
 * form.p-faux-ajax-intercept-me
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  function send(form) {
    var iframeName = "p-faux-ajax-" + new Date().getTime(),
      $iframe = $(
        '<iframe name="' + iframeName + '" style="display: none;"></iframe>'
      );

    $("body").append($iframe);
    form.setAttribute("target", iframeName);

    $iframe.one("load", function () {
      var text = $(this.contentDocument.body).text();
      var gotJSON = true;
      try {
        var data = JSON.parse(text);
      } catch (e) {
        gotJSON = false;
      } finally {
        if (gotJSON) {
          $(document)
            .trigger("bb:preHandleData", data)
            .trigger("bb:handleData", data)
            .trigger("bb:postHandleData", data);
        }
      }
      form.removeAttribute("target");
      $iframe.remove();
    });
    $(form).trigger("submit", true);
    return form;
  }

  $(doc).on(
    "submit",
    "form.p-faux-ajax-intercept-me",
    function (ev, synthetic) {
      if (!synthetic) send(this, true);
      else return;
    }
  );

  /**
   * Export fauxAjax function
   */
  bb.Plugins = $.extend({}, bb.Plugins, {
    "faux-ajax": {
      send: send
    }
  });
})(jQuery, window, document);
