/* toelichtingen:
 *
 * Change font-class of 'toelichtingen'
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups) {
        $.each(data.groups, function (_, group) {
          $.each(group.controls, function (_, control) {
            if (control["font-class"].toLowerCase() === "standard remark") {
              if (/^Toelichting/.test(control.value))
                control["font-class"] = "toelichting";
            }
          });
        });
      }
    });
  });
})(jQuery, window, document);
