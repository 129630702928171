import { gt, bb, _ } from "$json";
import { Mustache } from "$mustache";
/* smart-attachments:
 *
 * Attach files smartly
 *
 * @dependencies: Mustache, faux-ajax
 *
 * For authors: define a dataset as "DatasetSmartAttachments"; the
 * last cell will then be used as smart attachments widget.
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var finder = bb.propFinder(bb.conf, "arbitrary.smart-attachments");

  var translations = {
    "Please provide just one file at a time": {
      nl: "Voeg maximaal 1 bestand toe per keer"
    },
    "File is too large ({0}). Maximum file size allowed: {1}": {
      nl: "Bestand is te groot ({0}). Maximum toegestane grootte: {1}"
    },
    "We only accept the following formats: ": {
      nl: "Alleen de volgende bestandsformaten worden ondersteund: "
    }
  };

  $(function () {
    gt.addTranslations(translations);
  });

  function humanSize(size) {
    if (size > 1024 * 1024)
      return (Math.round((size * 100) / (1024 * 1024)) / 100).toString() + "MB";
    else return (Math.round((size * 100) / 1024) / 100).toString() + "KB";
  }

  var $doc = $(doc),
    creds = {},
    addform,
    delform,
    forms;

  var isAdvancedUpload = (function () {
    var div = document.createElement("div");
    return (
      ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
      "FormData" in window &&
      "FileReader" in window
    );
  })();

  if (isAdvancedUpload) {
    var droppedFiles = false;

    $doc
      .on(
        "drag dragstart dragend dragover dragenter dragleave drop",
        ".p-smart-attachments-upload",
        function (e) {
          e.preventDefault();
          e.stopPropagation();
        }
      )
      .on("dragover dragenter", ".p-smart-attachments-upload", function () {
        $(this).addClass("is-dragover");
      })
      .on("dragleave dragend drop", ".p-smart-attachments-upload", function () {
        $(this).removeClass("is-dragover");
      })
      .on("drop", ".p-smart-attachments-upload", function (e) {
        var droppedFiles = e.originalEvent.dataTransfer.files,
          err;
        advancedUpload(e, this, droppedFiles);
      });
  }

  $(function () {
    addform = $("#p-smart-attachments-addform").html();
    delform = $("#p-smart-attachments-delform").html();
    forms = addform + delform;
    if (!(addform || delform))
      throw new Error(
        "Plugin smart-attachments expected Mustache template #p-smart-attachments-addform" +
          " and #p-smart-attachment-delform. One or both of them are missing"
      );
  });

  function getErrorElt(form) {
    return form.querySelector(".p-smart-attachments-error");
  }

  function clearError(form) {
    var elt = getErrorElt(form);
    if (elt) {
      elt.innerHTML = "";
      elt.style.display = "none";
    }
  }

  function setError(form, error) {
    var elt = getErrorElt(form);
    if (elt) {
      elt.innerHTML = error;
      elt.style.display = "";
    } else {
      bb.notify(
        "Plugin smart-attachments would like to have a .p-smart-attachments-error " +
          "inside .p-smart-attachments-upload, so that can show the error inline."
      );
      $doc.trigger("bb:preHandleData", { error: error });
      $doc.trigger("bb:handleData", { error: error });
      $doc.trigger("bb:postHandleData", { error: error });
    }
  }

  function rejectExtension(path) {
    if (finder("accepts.types")) {
      if (
        finder("accepts.types").indexOf(path.split(".").pop().toLowerCase()) ===
        -1
      ) {
        return finder(
          "accepts.text",
          _("We only accept the following formats: ") +
            finder("accepts.types").join("  ")
        );
      }
    }
    return undefined;
  }

  function advancedUpload(e, form, droppedFiles) {
    e.preventDefault();

    var err;
    // It happens in IE that this function is called twice. Do nothing
    // if that apparently happens.
    if (droppedFiles.length === 0) {
      return;
    }
    if (droppedFiles.length > 1) {
      setError(form, _("Please provide just one file at a time"));
      return;
    }

    var ajaxData = new FormData(form),
      tag = form["tag"].value,
      maxsize = form["addme"].getAttribute("data-maxsize"),
      file = droppedFiles.item(0);

    var errExtension = rejectExtension(file.name);
    if (errExtension) {
      setError(form, errExtension);
      return;
    }

    if (file.size > maxsize) {
      setError(
        form,
        bb.positionalFormat(
          _("File is too large ({0}). Maximum file size allowed: {1}"),
          humanSize(file.size),
          humanSize(maxsize)
        )
      );
      return;
    }

    clearError(form);
    ajaxData.append("addable", file);

    var xhr = $.ajax({
      url: form.getAttribute("action"),
      type: form.getAttribute("method"),
      data: ajaxData,
      dataType: "json",
      cache: false,
      contentType: false,
      processData: false,
      complete: function () {
        form.classList.add("is-uploading");
      },
      success: function (data, textStatus, xhr) {
        form.classList.add(data.success == true ? "is-success" : "is-error");
        // @todo: sanity check
        if (data.error) {
          this.error(data, textStatus, xhr);
        } else {
          $doc
            .trigger("bb:preHandleData", data)
            .trigger("bb:handleData", data)
            .trigger("bb:postHandleData", data);
        }
      },
      error: function (xhr, evtname, textStatus) {
        var m;
        if (xhr.responseJSON) {
          onStructuredError(xhr.responseJSON, form);
        } else if (!xhr) {
          setError(form, "Unknown Error");
        } else if (xhr.responseText) {
          setError(form, xhr.status + " " + xhr.responseText);
        } else if (xhr.status < 200 || xhr.status >= 400) {
          setError(form, "Error " + xhr.status + ": " + textStatus);
        }
      },
      xhr: function () {
        var xhr = new window.XMLHttpRequest();
        //Upload progress
        xhr.upload.addEventListener(
          "progress",
          function (evt) {
            if (evt.lengthComputable) {
              showProgress(file, tag, { loaded: evt.loaded, total: evt.total });
            }
          },
          false
        );
        //Download progress
        // xhr.addEventListener('progress', function(evt){
        //   if (evt.lengthComputable) {
        //     var percentComplete = evt.loaded / evt.total;
        //     //Do something with download progress
        //     console.log(percentComplete);
        //   }
        // }, false);
        return xhr;
      }
    });
  }

  function uploadProgress() {
    console.log("prog", arguments);
  }

  Mustache.tags = ["[[", "]]"];

  function test(data) {
    return (
      data &&
      data.groups &&
      data.groups.some(function (group) {
        return (
          group.current &&
          group.controls.some(function (control) {
            return control.datatype === "DatasetSmartAttachments";
          })
        );
      })
    );
  }

  function isnew(data) {
    return (
      creds.uniquid !== data.uniqueid ||
      creds.sessionid !== data.sessionid ||
      creds.modelid !== data.modelid
    );
  }

  function remember(data) {
    creds.uniqueid = data.uniqueid;
    creds.sessionid = data.sessionid;
    creds.modelid = data.modelid;
  }

  function myown(data) {
    return typeof data.filelist !== "undefined";
  }

  function getCells() {
    return $(
      '.group.selected [data-datatype="DatasetSmartAttachments"] td:last-child'
    );
  }

  function enhance(data) {
    var $cells = getCells(),
      tags = [],
      unassoc;

    // We probably started uploading, but did navigation in the mean
    // time. Ignore the response in that case.
    if ($cells.length === 0) return;

    $cells.each(function () {
      var $this = $(this),
        $label = $this.find(":first"),
        attachment = $label.data("control"),
        files,
        html,
        tr,
        permit,
        tag;

      tr = this.parentNode;
      while (tr && !$(tr).hasClass("bb-newscope"))
        tr = tr.parentNode.rows[tr.rowIndex - 1];

      if (tr === undefined) tr = this.parentNode;

      permit = $(tr.cells[0]).find(":first").data("control");
      // Just to be sure, although we also normalized form-widgets-definitiosn
      tag = permit.value + "/" + attachment.value.replace(/\r\n/g, "\n");

      files = $.grep(data.filelist, function (file) {
        return file.tag === tag;
      });
      (html = Mustache.render(
        forms,
        $.extend({}, creds, {
          tag: tag,
          name: attachment.value,
          files: files,
          heading: "Toegevoegde bijlagen",
          candrop: isAdvancedUpload,
          hasfiles: files.length > 0
        })
      )),
        // console.log(this, permit.value, $label.data('control').value);

        tags.push(tag);
      if (tag !== "geen/geen") {
        $this.toggleClass("p-smart-attachments-any", files.length !== 0);
        if (!$this.hasClass("p-smart-attachments-initted")) {
          $label
            .get(0)
            .insertAdjacentHTML(
              "afterend",
              '<div class="p-smart-attachments-form"></div>'
            );
          $label.attr("hidden", true);
          $this.addClass("p-smart-attachments-initted");
        }
        var $form = $this.find(".p-smart-attachments-form");
        $form.get(0).innerHTML = html;
        $form.find('[data-md="true"]').each(function (_, mdable) {
          $(this).showdown();
          $(document).trigger("bb:resized");
        });
      }
    });
    unassoc = getUnassociatedFiles(data, tags);
    $(".p-smart-attachments-unassociated").html(
      Mustache.render(
        delform,
        $.extend({}, creds, {
          files: unassoc,
          heading:
            "Onderstaande bijlagen lijken nu niet meer van toepassing." +
            " Verwijder ze zo nodig.",
          hasfiles: unassoc.length > 0
        })
      )
    );
    $doc.trigger("bb:resized");
  }

  function showProgress(file, tag, stats) {
    var cells = getCells().toArray();
    // We probably started uploading, but did navigation in the mean
    // time. Ignore the response in that case.
    if (cells.length === 0) return;

    var progress = stats.loaded / stats.total;
    cells.some(function (c) {
      var $this = $(c),
        thistag = $this.find(":first").text(),
        el;
      if (tag === thistag) {
        el = c.querySelector('[data-progresses="' + file.name + '"]');
        if (progress === 1) {
          if (el) {
            el.parentNode.removeChild(el);
          }
        } else {
          if (el) {
            el.querySelector("progress").value = progress;
          } else {
            c.insertAdjacentHTML(
              "beforeend",
              '<div class="p-smart-attachments-progress__wrap"' +
                ' data-progresses="' +
                file.name +
                '">' +
                '<progress class="p-smart-attachments-progress__bar" ' +
                'value="' +
                progress +
                '">' +
                "</progress>" +
                '<span class="p-smart-attachments-progress__filename">' +
                file.name +
                "</span>" +
                "</div>"
            );
          }
        }
        $doc.trigger("bb:resized");
        return true;
      } else {
        return false;
      }
    });

    // console.log(progress, file, tag);
  }

  function getUnassociatedFiles(data, tags) {
    return data.filelist.filter(function (file) {
      return tags.indexOf(file.tag) === -1;
    });
  }

  function onStructuredError(data, form) {
    var m;
    if (data.error.code === 29) {
      m = data.error.summary.match(
        /^Uploaded file \(([0-9]+) KB\) is too big \(max ([0-9]+) KB\)$/
      );
      if (m)
        setError(
          form,
          bb.positionalFormat(
            _("File is too large ({0}). Maximum file size allowed: {1}"),
            humanSize(m[1] * 1024),
            humanSize(m[2] * 1024)
          )
        );
    }
    if (!m)
      // either undefined (code != 29) or no match (null)
      $doc
        .trigger("bb:preHandleData", data)
        .trigger("bb:handleData", data)
        .trigger("bb:postHandleData", data);
  }

  $(doc).on("focus", ".p-smart-attachments-upload-widget", function (e) {
    $(this).addClass("focus");
  });
  $(doc).on("blur", ".p-smart-attachments-upload-widget", function (e) {
    $(this).removeClass("focus");
  });

  $(doc).on("change", '[name="addme"]', function (e) {
    if (isAdvancedUpload) {
      var droppedFiles = this.files;

      // Important - we do not want to double-send this one
      this.setAttribute("disabled", true);
      advancedUpload(e, this.form, droppedFiles);
      this.removeAttribute("disabled");
      this.value = "";
    } else {
      this.setAttribute("name", "addable");
      var errExtension = rejectExtension(this.value);
      if (errExtension) {
        setError(this.form, errExtension);
      } else {
        bb.Plugins["faux-ajax"].send(this.form);
      }

      // Listen again
      this.setAttribute("name", "addme");
      // We don't want this value inside FormData (causing havoc in
      // Dragging uploader)
      this.value = "";
    }
  });

  $(function () {
    $(doc).on("bb:postHandleData", function (event, data) {
      if (test(data)) {
        if (isnew(data)) {
          remember(data);
        }
        bb.ajax.post({
          url: "getfiles",
          data: $.extend({}, creds, { fmt: "json" })
        });
      }
      if (myown(data)) {
        enhance(data);
      } else {
        $(".p-smart-attachments-unassociated").html("");
      }
    });
  });
})(jQuery, window, document);
