import { bb } from "$json";
/* has-no-next:
 *
 * Set body.hasNoNext when the current node name matches a
 * predefined regular expression, and unsets it when does not.
 *
 * Author: Niels Giesen
 *
 * Copyright (C) 2011, 2012 by Berkeley Bridge
 *
 */

(function ($) {
  // Regular expression to test node names against:
  var re = /(einde\.einde_balie|\.fout)$/;

  $(document).on("bb:postHandleData", function (event, data) {
    if (data && data.jumplist)
      bb.Mode.toggle(
        "hasNoNext",
        data.hasnext === false ||
          data.groups.some(function (group) {
            return group.current && re.test(group.name);
          }) ||
          data.groups.some(function (group) {
            return (
              group.current &&
              group.name === "main.uitkomsten" &&
              group.controls.some(function (control) {
                return (
                  typeof control.value === "string" &&
                  control.value.match(/^Dit is het einde van/)
                );
              })
            );
          })
      );
  });
})(jQuery);
