import { bb } from "$json";
/* geen-toestemmingen:
 *
 * Set body.p-geen-toestemmingen when the current node is
 * main.uitkomsten AND there is no label indicating the end...
 *
 * Author: Niels Giesen
 *
 * Copyright (C) 2011, 2012 by Berkeley Bridge
 *
 */

(function ($) {
  $(document).on("bb:preHandleData", function (event, data) {
    if (data && data.jumplist)
      bb.Mode.toggle(
        "p-geen-toestemmingen",
        data.groups.some(function (group) {
          return (
            group.current &&
            group.name === "main.uitkomsten" &&
            !group.controls.some(function (control) {
              return (
                typeof control.value === "string" &&
                control.value.match(/^Dit is het einde van/)
              );
            })
          );
        })
      );
  });
})(jQuery);
