import { bb } from "$json";
/* detect-iframe:
 *
 * detect iframe
 *
 * Author: Niels Giesen
 * Copyright 2017 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    if (top === self) {
      bb.Mode.set("isStandalone");
    } else {
      bb.Mode.set("isIframed");
    }
  });
})(jQuery, window, document);
