/* grid-rowspan:
 *
 * - Add rowspan=x to (link-)label cells repeated over subsequent rows
 * - Remove like cells.
 *
 * Author: Niels Giesen
 * Copyright 2015 Berkeley Bridge
 */
(function ($) {
  $(function () {
    $(document).on("bb:preHandleData", rowspanifyData);
  });

  /**
   * @param {Event}  event The event that triggered this function.
   * @param {Object} data  The JSON-object as defined in our JSON API.
   *
   * NOTE: this function is descructive - it may change the data
   * argument.
   */
  function rowspanifyData(event, data) {
    if (typeof data != "undefined" && data && data.groups)
      $.each(data.groups, function (_, group) {
        $.each(group.controls, function (_, control) {
          if (
            control.controltype == "grid" &&
            !/norowspan/.test(control["font-class"])
          ) {
            rowspan(control);
          }
        });
      });
  }

  /**
   * Set (link-)label cell values to null when coming beneath other
   * cells with same value (and same url in case of linklabels).
   *
   * Give those other cells appropriate _rowspan values.
   *
   * @param {Object} control A control object as defined in our JSON API.
   *
   * NOTE: this function is descructive - it may change the control
   * argument.
   */
  function rowspan(control) {
    var column = control.columns[0],
      i = 0;

    if (column.controltype === "label" || column.controltype === "linklabel") {
      var cur, last, len;

      len = control.value.length;

      while (len-- > 0) {
        cur = control.value[len][i];
        cur._rowspan = 1;

        if (last && last.value === cur.value) {
          if (column.controltype !== "linklabel" || last.url === cur.url) {
            cur._rowspan = last._rowspan + 1;
            last.value = null;
          }
        }

        last = cur;
      }
    }
  }
})(jQuery);
