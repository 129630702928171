/* global jQuery */
import { _, bb } from "$json";
import { fromApiServer } from "$json/lib/location";
import { dialogPolyfill } from "$dialog-polyfill";
/* savecase-native-dialog:
 *
 * Download and upload cases
 *
 * Author: Niels Giesen
 * Copyright 2018 Berkeley Bridge
 *
 */
(function ($, document) {
  $(function () {
    var $doc = $(document),
      $iframe = $(
        '<iframe id="bb-p-uploadert" style="display:none" name="bb-p-uploadert"></iframe>'
      ),
      $form = $(
        `<dialog>
          <form
            target="bb-p-uploadert"
            action="${fromApiServer("loadcase?fmt=json")}"
            id="bb-p-loadcaseform"
            accept="text/plain"
            enctype="multipart/form-data"
            method="post"
          >
            <div>
              <input type="hidden" name="uniqueid" />
              <input type="hidden" name="dbname" />
              <input type="hidden" name="sessionid" />
              <input
                type="file"
                name="case"
                required="required"
                id="bb-loadcasefile"
                accept=".cas"
              />
              <p>${_("Choose the session file you saved earlier")}</p>
              <input name="add" type="submit" class="submit" value="${_(
                "load"
              )}"
              /> <input name="cancel" type="reset" value="${_("cancel")}" />
            </div>
          </form>
        </dialog>`
      );

    let focus;
    var dialog = $form.get(0);
    dialogPolyfill.registerDialog(dialog);
    // Memorize state vars
    var uid, sid, db;

    $iframe.appendTo("body").on("load", function () {
      var text = $(this.contentDocument.body).text();
      var gotJSON = true;
      try {
        var data = JSON.parse(text);
      } catch (e) {
        gotJSON = false;
      } finally {
        if (gotJSON) {
          $doc
            .trigger("bb:preHandleData", data)
            .trigger("bb:handleData", data)
            .trigger("bb:postHandleData", data);
        }
      }
    });

    $form.appendTo("body");
    dialog.addEventListener("close", function () {
      console.log(arguments);
    });
    $form
      .find("form")
      .on("submit", function () {
        dialog.close();
        $(".progress").fadeIn();
        bb.Mode.set("busy");
      })
      .find("[type=file]")
      .on("change", function () {
        $form.find("input[type=submit]").trigger("focus");
      })
      .end()
      .find("[type=reset]")
      .on("click", function () {
        dialog.close();
      });

    dialog.addEventListener("close", () => {
      focus && focus.focus();
    });
    // Listen on the 'load button' that may be anywhere on the page
    $("[data-rel=load]")
      .on("keydown", function (ev) {
        if (ev.keyCode === 13) {
          // Dispatch to click handler on ENTER.
          $(this).trigger("click");
          return false;
        }
        return true;
      })
      .on("click", function () {
        focus = this;
        dialog.showModal();
        const file = dialog.querySelector(`input[type="file"]`);
        file && file.focus();
      });

    $doc.on("bb:postHandleData", function (event, data) {
      if (data && data.uniqueid && data.sessionid && data.dbname) {
        if (
          uid !== data.uniqueid ||
          sid !== data.sessionid ||
          db !== data.dbname
        ) {
          uid = data.uniqueid;
          sid = data.sessionid;
          db = data.dbname;
          $("a[data-rel=save]").attr({
            href: fromApiServer(
              "savecase?" +
                $.param({
                  uniqueid: data.uniqueid,
                  sessionid: data.sessionid,
                  dbname: data.dbname
                })
            ),
            download:
              data.modelname +
              "." +
              data.sessionid +
              $.datepicker.formatDate("yymmdd", new Date()) +
              ".cas"
          });

          $form.find("input[type=hidden]").each(function () {
            var val = bb.getVar($(this).attr("name"));
            if (val) $(this).val(val);
          });
        }
      }
    });
  });
})(jQuery, document);
